<template>
  <div>
    <Navbar
      mode="solid"
      :show-btn-icon="true"
      :btn-link-path="backPath"
      :show-currency-picker="false"
      :show-avatar="false"
    />
    <main class="main">
      <div class="main-container">
        <h1 class="title">{{ $t('page.summary.title') }}</h1>
        <div class="booking-details">
          <div class="destination-block">
            <figure class="agency-img-container">
              <img class="agency-img" :src="agencyImg" alt="Agency agent" />
            </figure>
            <div class="destination-text-container">
              <h4 class="small-title">{{ $t('payment.summary.text_1') }}</h4>
              <div class="destination-flag-container">
                {{ offer.trip_destination }}
              </div>

              <p class="font-sans text-sm text-black-base">
                {{ $t('payment.summary.text_2') }} {{ agent.company_name }}
              </p>
            </div>
          </div>
          <Divider />
          <div class="dates">
            <h3 class="section-title">{{ $t('page.summary.your_trip') }}</h3>
            <div>
              <div class="fulldate-container">
              <div class="date-block">
                <span class="small-title">
                  {{ $t('payment.summary.start_date') }}
                </span>
                <span class="date">{{ startDate }}</span>
              </div>
              <i class="mx-6 text-lg bx bx-arrow-back bx-rotate-180"></i>
              <div class="date-block">
                <span class="small-title">
                  {{ $t('payment.summary.end_date') }}
                </span>
                <span class="date">{{ endDate }}</span>
              </div>
              </div>
              <div class="date-block">
                <span class="small-title">
                  {{ $t('common.travellers') }}
                </span>
                <span class="date">
                  {{ $tc('common.traveller', totalTravellers) }}
                </span>
              </div>
            </div>
          </div>
          <Divider/>
          <h3 class="section-title mb-2 mt-6">{{ $t('page.summary.price_details') }}</h3>
          <div class="details">
            <div class="row">
              <div class="column">
                {{ pricePerAdult }} ×
                {{ $tc('payment.summary.adults', amountOfAdults) }}
              </div>
              <div class="column">{{ totalAdultsPrice }}</div>
            </div>
            <Divider v-if="amountOfChildren > 0" />
            <div class="row" v-if="amountOfChildren > 0">
              <div class="column">
                {{ pricePerChild }} ×
                {{ $tc('payment.summary.children', amountOfChildren) }}
              </div>
              <div class="column">{{ totalChildrenPrice }}</div>
            </div>
            <Divider v-if="amountOfSingleRooms > 0" />
            <div class="row" v-if="amountOfSingleRooms > 0">
              <div class="column">
                {{ pricePerSingleRoom }} ×
                {{ $tc('payment.summary.room', amountOfSingleRooms) }}
              </div>
              <div class="column">{{ totalSingleRoomPrice }}</div>
            </div>
            <Divider />
            <div class="row">
              <div class="text-md font-semibold column">
                {{ $t('payment.summary.total') }}
              </div>
              <div class="text-md font-semibold column">{{ totalPrice }}</div>
            </div>
          </div>
        </div>
          <ButtonPrimary
            @click.native="checkOut"
            :text="`${$t('payment.button_book')}`"
            class="btn-submit" 
            :loading="btnLoading"
          />
      </div>

    </main>

    <SubFooter class="subfooter" />

  </div>
</template>

<script>
import dayjs from "dayjs";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import Divider from "@/components/dividers/Divider";
import SubFooter from "@/components/layout/SubFooter";
import { mapActions, mapGetters } from "vuex";
import { getStartDate, getEndDate, locale } from "@/shared/dateHandler.js";
import api from "@/api";
import Navbar from "@/components/layout/Navbar.vue";
export default {
  name: "PaymentSummary",
  components: {
    Navbar,
    ButtonPrimary,
    Divider,
    SubFooter,
  },
  data() {
    return {
      loading: false,
      btnLoading:false,
      bookingDetails: false,
      transactionId: undefined,
    };
  },
  methods: {
    ...mapActions("user", [
      "createPayment",
      "confirmPayment",
      "getSingleTripRequest",
      "getTripAgent",
    ]),
    ...mapActions("request", [
      "setThankYouPage",
      "setTripRequestCheckout",
    ]),
    async checkOut() {
      try {
        this.btnLoading = true;
        document.body.style.pointerEvents = "none";

        const res = await api.createCheckoutSession(this.offer.id);

        this.setTripRequestCheckout({
          lang: localStorage.getItem("lang") || "en",
          offer_id: this.offer.id,
          checkout_session_id: res.data.checkout_session.id,
          transaction_id: this.offer.transaction_id,
        });

        console.log(this.tripRequestCheckout);

        this.setThankYouPage(true);
        // Uncomment if redirect is needed
        window.location.href = res.data.checkout_session.url;
      } catch (err) {
        console.error('Error during checkout:', err);
      } finally {
        document.body.style.pointerEvents = "";
        this.btnLoading = false;
      }
    },
    async getTrip() {
      try {
        this.loading = true;

        const res = await this.getSingleTripRequest(this.$route.query.trip_id);
        if (!res) return false;

        if (Object.keys(this.offer).length > 0) {
          const agencyId = this.offer.trip_agencies.split(",")[0];
          if (agencyId) {
            await this.getTripAgent(agencyId);
          }
        }
      } catch (error) {
        console.error('Error fetching trip:', error);
      } finally {
        this.loading = false;
      }
    },
    calculateEndTime() {
      const endTime = dayjs(new Date()).format("hh:mm:ss");
      localStorage.setItem("end_book_time", endTime);
    },
    async setBookingEndTime() {
      await this.$store.dispatch("tracker/setBookingEnd");
    },
    async recordBookingDuration() {
      await this.$store.dispatch("tracker/recordBookingDuration");
    },
  },
  computed: {
    ...mapGetters("user", ["offer", "agent", "user"]),
    ...mapGetters("request", ["tripRequestCheckout"]),
    backPath() {
      return `/trips/request?id=${this.offer.trip_request_id}`
    },
    currencyRegion() {
      let currency = this.offer.trip_currency;
      if (currency === "USD") return "en-US";
      if (currency === "CHF") return "en-CH";
      if (currency === "GBP") return "en-EN";
      return "fr-FR";
    },
    agencyImg() {
      return `${process.env.VUE_APP_FILE_BASE_PATH}${this.agent.profile_photo}`;
    },
    isTripDate() {
      return Object.keys(this.offer).length;
    },
    daysInDates() {
      if (this.isTripDate) {
        const date1 = this.offer.trip_dates.split("|")[0].trim();
        const date2 = this.offer.trip_dates.split("|")[1].trim();
        const parseDate1 = dayjs(date1);
        const parseDate2 = dayjs(date2);

        return parseDate2.diff(parseDate1, "days");
      }
      return 0;
    },
    startDate() {
      if (this.isTripDate) {
        return getStartDate(this.offer.trip_dates).locale(locale).format("ddd, DD MMM YYYY");
      }
      return "";
    },
    endDate() {
      if (this.isTripDate) {
        return getEndDate(this.offer.trip_dates).locale(locale).format("ddd, DD MMM YYYY");
      }
      return "";
    },
    amountOfChildren() {
      return this.offer.trip_children;
    },
    amountOfAdults() {
      return this.offer.trip_adults;
    },
    amountOfSingleRooms() {
      return this.offer.trip_single_rooms;
    },
    earningFees() {
      return 1 + this.offer.trip_earning_fees / 100;
    },
    pricePerSingleRoom() {
      let price = this.offer.trip_single_room_price;
      return this.$n(price, "currency", this.currencyRegion);
    },
    pricePerAdult() {
      let price = this.offer.trip_price_per_adult * this.earningFees;
      return this.$n(price, "currency", this.currencyRegion);
    },
    pricePerChild() {
      let price = this.offer.trip_price_per_child * this.earningFees;
      return this.$n(price, "currency", this.currencyRegion);
    },
    totalAdult() {
      return (
        this.offer.trip_price_per_adult *
        this.offer.trip_adults *
        this.earningFees
      );
    },
    totalChildren() {
      return (
        this.offer.trip_price_per_child *
        this.offer.trip_children *
        this.earningFees
      );
    },
    totalTravellers() {
      return this.offer.trip_adults + this.offer.trip_children;
    },
    totalSingleRoom() {
      return this.offer.trip_single_room_price * this.offer.trip_single_rooms;
    },
    totalChildrenPrice() {
      return this.$n(this.totalChildren, "currency", this.currencyRegion);
    },
    totalAdultsPrice() {
      return this.$n(this.totalAdult, "currency", this.currencyRegion);
    },
    totalSingleRoomPrice() {
      return this.$n(this.totalSingleRoom, "currency", this.currencyRegion);
    },
    totalPrice() {
      let total = this.totalAdult + this.totalChildren + this.totalSingleRoom;
      return this.$n(total, "currency", this.currencyRegion);
    },
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-y-hidden");
  },
  async beforeMount() {
    if (!Object.keys(this.offer).length || !Object.keys(this.agent).length) {
      await this.getTrip();
      this.transactionId = this.offer.transaction_id;
      console.log('On mounted', this.transactionId)
    }
  },
};
</script>

<style scoped>
.body {
  @apply md:p-20 sm:p-8 p-4 pt-20 md:pt-40 md:pb-0 w-full;
}

.main {
  @apply relative w-full max-w-lg transform -translate-x-1/2 left-1/2;
  @apply pt-20 md:pt-32 px-4;
}

.main-container{
  @apply w-full pb-40;
}

.subfooter {
  @apply relative w-full bg-white max-w-lg transform;
  @apply -translate-x-1/2 left-1/2 xxl:fixed px-4 sm:px-0;
}

.title {
  @apply font-sans text-left font-bold text-black-base text-4xl mb-4;
}

.destination-block {
  @apply py-4 flex items-center;
}

.agency-img-container {
  @apply relative overflow-hidden h-14 w-14 rounded-full mr-3;
}

.agency-img {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}

.dates {
  @apply py-4;
}
.details{
  @apply pb-4;
}
.days-badge {
  @apply h-14 w-14 flex items-center flex-wrap justify-center bg-black-base rounded-xl mr-4;
}

.days-badge-text {
  @apply w-full text-center text-sm;
}

.date-block {
  @apply py-3;
}

.small-title {
  @apply text-black-lightest text-xxs font-bold uppercase block;
}

.destination-flag-container {
  @apply font-sans text-black-base flex items-center;
}

.destination-flag {
  @apply relative overflow-hidden w-6 h-6 mr-2;
}

.flag {
  @apply h-full absolute top-0 left-0;
}

.date {
  @apply font-sans text-black-base text-base;
}

.row {
  @apply flex justify-between py-3;
}

.divider {
  @apply w-full border-b border-grey-base;
}

.booking-details-text {
  @apply flex items-center border-none shadow-none text-xl sm:text-2xl;
}

.btn-submit {
  @apply w-full h-12 mt-8 ml-auto;
}

.fulldate-container{
  @apply flex items-center;
}
.section-title{
  @apply font-bold text-lg text-black-base;
}

.navbar {
  @apply border-none;
  @apply fixed z-40;
}
</style>
